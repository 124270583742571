import { DatePicker, Form, Input, message, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { ContragentAutocomplete, DocSalesAutoComplete } from "src/shared";
import dayjs from "dayjs";
import { axiosInstance } from "src/shared/api/axiosInstance";
import { EditDocsSales } from "src/features/Modal";
import CreateDocsSalesWrapper from "src/features/Modal/ui/CreateDocsSalesWrapper";
import { CashboxUsersAutoComplete } from "src/shared";
import { apiRequests } from "src/shared/api/api";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Rent = (props) => {
  const [priceCache, setPriceCache] = useState([]);
  const [docSales, setDocSales] = useState([]);
  const [isNewDocSales, setIsNewDocSales] = useState(false);
  message.destroy();

  const calcPrice = async (id) => {
    const nomenclatures = [props.item.group];
    const first_date = dayjs(props.item.start).startOf("day");
    const second_date =
      props.item.end &&
      dayjs(props.item.end).isAfter(dayjs(props.item.start).add(1, "month"))
        ? dayjs(props.item.end).startOf("day")
        : dayjs(props.item.start).add(1, "month");

    let date_difference_months = second_date.diff(first_date, "month");
    if (date_difference_months <= 0) {
      date_difference_months = 1;
    }

    if (nomenclatures.length > 0) {
      const requests = nomenclatures.map((item) => {
        const cache = priceCache.find((i) => i.id === item);
        if (cache) {
          return cache.data;
        } else {
          return axiosInstance.get(`/alt_prices/${item}/`).catch((e) => e);
        }
      });
      Promise.allSettled(requests)
        .then((result) => {
          const price = result.map((item) => {
            if (item.status === "fulfilled") {
              if (typeof item.value === "number") {
                // Умножаем цену на количество месяцев
                return Number(item.value) * Number(date_difference_months);
              } else if (item.value.status === 200) {
                setPriceCache((prev) => [
                  ...prev,
                  {
                    id: item.value.data.nomenclature_id,
                    data: item.value.data.price,
                  },
                ]);

                // Умножаем цену на количество месяцев
                return item.value.data.price * date_difference_months;
              }
            }
            return 0;
          });
          const value =
            price?.filter(Number).reduce((acc, val) => acc + val, 0) || 0;

          props.form.setFieldValue("sum_period", value.toFixed(2));
        })
        .catch((e) => console.log("e", e));
    }
  };

  useEffect(() => {
    calcPrice();
    // eslint-disable-next-line
  }, [props.item, props.docSalesId]);

  const onSelectContragent = (val) => {
    if (val !== undefined) {
      apiRequests.contragents.getItem(String(val)).then((res) => {
        props.setContragent(res.data);
        props.form.setFieldValue("contragent", res.data.id);
      });
    }
  };

  const onSelectContragentAccept = (val) => {
    if (val !== undefined) {
      apiRequests.contragents.getItem(val).then((res) => {
        props.setContragentAccept(res.data);
        props.form.setFieldValue("contragent_accept", res.data.id);
      });
    }
  };

  useEffect(() => {
    if (props.contragentsIds) {
      onSelectContragent(props.contragentsIds[0]);
      onSelectContragentAccept(props.contragentsIds[1]);
    }
    // eslint-disable-next-line
  }, [props.contragentsIds]);

  useEffect(() => {
    if (props.docSalesId) {
      apiRequests.docs.salesItem(props.docSalesId).then((res) => {
        setDocSales(res.data);
      });
    }
    // eslint-disable-next-line
  }, [props.docSalesId]);

  const handleSaveDocSale = (data) => {
    props.setDocSalesId(data.id);
    if (data.contragent) {
      onSelectContragent(data.contragent);
      onSelectContragentAccept(data.contragent);
    }
    props.form.setFieldValue("docs_sales_id", data.id);
    setIsNewDocSales(true);
  };

  return (
    <Form form={props.form}>
      <Form.Item name="title" label="Название">
        <Input />
      </Form.Item>
      <Form.Item
        name={"date_range"}
        label="Период брони"
        rules={[{ required: true }]}
      >
        <RangePicker
          placeholder={["Начальная дата", "Конечная дата"]}
          format={"DD.MM.YYYY"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name={"sum_period"} label="Сумма за период">
        <Input type="number" addonAfter="₽" />
      </Form.Item>
      <Form.Item
        initialValue={"Не оплачен"}
        name={"status_doc_sales"}
        label="Статус оплаты"
      >
        <Select
          placeholder="Статус оплаты"
          options={[
            { value: "Оплачен", label: "Оплачен" },
            { value: "Не оплачен", label: "Не оплачен" },
            { value: "Частично оплачен", label: "Частично оплачен" },
          ]}
        />
      </Form.Item>
      <Form.Item
        initialValue={"Новый"}
        name={"status_booking"}
        label="Статус брони"
      >
        <Select
          placeholder="Статус брони"
          options={[
            { value: "Новый", label: "Новый" },
            { value: "Подтвержден", label: "Подтвержден" },
            { value: "Оплачен", label: "Оплачен" },
            { value: "Забран", label: "Забран" },
            { value: "Доставлен", label: "Доставлен" },
            { value: "Выгружен", label: "Выгружен" },
            { value: "Пролонгирован", label: "Пролонгирован" },
            { value: "Завершен", label: "Завершен" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={"date_app"}
        label="Дата заявки"
        rules={[{ required: true }]}
        initialValue={dayjs(new Date())}
      >
        <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY"} />
      </Form.Item>
      <Form.Item
        name={"booking_driver_id"}
        label="Водитель"
        rules={[{ required: true }]}
        style={{ marginBottom: 15 }}
      >
        <CashboxUsersAutoComplete
          token={props.token}
          onSelect={(obj) => {
            if (obj) {
              props.form.setFieldValue("booking_driver_id", obj);
            }
          }}
        />
      </Form.Item>
      {props.docSalesId ? (
        <Space style={{ marginBottom: 15 }}>
          <span style={{ color: "green" }}>Продажа успешно создана!</span>
          <EditDocsSales
            token={props.token}
            tags={""}
            updateRow={() => {}}
            doc={docSales}
          />
        </Space>
      ) : (
        <CreateDocsSalesWrapper
          token={props.token}
          updateRow={() => {}}
          onSave={handleSaveDocSale}
          tags={""}
          phone={props.contragent?.phone}
          name={props.contragent?.name}
          rent_nomenclature={
            props.selectedItem?.group ? props.selectedItem.group : undefined
          }
        />
      )}
      <Form.Item
        name={"contragent"}
        label="Клиент"
        rules={[{ required: true }]}
      >
        <ContragentAutocomplete
          api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
          token={props.token}
          width="100%"
          allowClear={true}
          onSelect={(obj) => {
            if (obj) {
              onSelectContragent(obj);
            }
          }}
          contragent={props.contragent}
        />
      </Form.Item>
      <Form.Item
        name={"contragent_accept"}
        label="Кто принимает"
        rules={[{ required: true }]}
      >
        <ContragentAutocomplete
          api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
          token={props.token}
          width="100%"
          placeholder="Введите контрагента"
          allowClear={true}
          onSelect={(obj) => {
            if (obj) {
              onSelectContragentAccept(obj);
            }
          }}
          contragent={props.contragentAccept}
        />
      </Form.Item>
      <Form.Item initialValue={"Месяц"} name={"tariff"} label="Тариф">
        <Select
          placeholder="Выберите тариф"
          options={[{ value: "month", label: "Месяц" }]}
        />
      </Form.Item>
      <Form.Item
        name={"docs_sales_id"}
        label="Заказ"
        rules={[{ required: true }]}
      >
        <DocSalesAutoComplete
          token={props.token}
          docSalesId={props.docSalesId}
          setDocSalesId={props.setDocSalesId}
          isNewDocSales={isNewDocSales}
          setIsNewDocSales={setIsNewDocSales}
          select={(obj) => {
            if (obj) {
              props.form.setFieldValue("docs_sales_id", obj);
              props.setDocSalesId(obj);
              apiRequests.docs.salesItem(obj).then((res) => {
                setDocSales(res.data);
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item name={"address"} label="Адрес">
        <Input />
      </Form.Item>
      <Form.Item name={"comment"} label="Примечание">
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};

export default Rent;
